import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Row, Column, Image, Section, Text } from 'components'
import Blog from 'images/blogs/blog-15.png'
import 'components/css/stories.css'
import HostName from '../../data/static/HostNames'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/WhyIsDigipayAGoodBusiness`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: 'Why Is Digipay A Good Business This 2020?',
  },
  {
    property: 'og:description',
    content:
      'Kung pinag-iisipan mong mag-negosyo this year, basahin mo ‘to ka-Digipay.',
  },
  {
    property: 'og:image',
    content: Blog,
  },
]

const ReasonsWhy = () => (
  <Layout>
    <Helmet title="Why Is Digipay A Good Business This 2020?" meta={SITE_META}>
      <html lang="en" />
    </Helmet>

    <Row>
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
        <Image
          className="blog__photo-banner"
          width="100%"
          mb="sp3"
          src={Blog}
        />
        <Heading
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          WHY IS DIGIPAY A GOOD BUSINESS THIS 2020?
        </Heading>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            PANAHON NA PARA LUMEVEL UP!
          </Heading>
          <Text>
            2020 na, papahuli ka ba? Kahit nasa bahay ka lang, pwede kang maging
            authorized payment center ng buong barangay! Dahil sa Digipay,
            madami kang pwedeng pagkakitaan. Mayroon itong bills payment,
            e-load, gaming pins, mobile money at microinsurance. Kaya kung may
            sarili kang sari-sari store, canteen, karinderya o kahit anong
            tindahan, dagdagan mo pa ng Digipay para magkaroon ka ng extra
            income. Uy, level up!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            SAFE AT SIGURADO
          </Heading>
          <Text>
            Sa pagsisimula ng negosyo, marami talagang duda. Kaya kung gusto mo
            ng safe at sigurado, i-Digipay mo na! May sariling app ang Digipay
            to ensure that all transactions are being monitored by our Sales
            Team. To sign-up naman as Digipay Agent, kailangan munang mag-submit
            ng proof of identity at dumaan sa verification process. Nakaabang
            din ang aming Customer Support para i-assist ka kung meron mang
            questions at concerns. Kaya sa Digipay, sure ka na you’re in good
            hands.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            DIGITINDAHAN IS IN
          </Heading>
          <Text>
            Hindi na uso ang pumila nang mahaba at ma-trapik para lang magbayad
            ng bills. Kaya maging hulog ng langit sa iyong mga kapitbahay bilang
            isang Digipay Agent! Lalayo pa ba sila kung may malapit namang
            payment center sa inyong barangay? Mapa-kuryente, tubig, credit
            card, loans, government bills o tuition man yan, isang bayaran na
            lang sa iyong digitindahan. Bukod sa nakatipid na sila sa oras,
            convenient at wala pang hassle!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            PUHUNAN IS NOT THE PROBLEM
          </Heading>
          <Text>
            Ikaw rin ba ‘yung gustong mag-negosyo pero hindi alam kung saan
            kukuha ng puhunan? Put that problem away dahil nandito naman ang
            Digipay! Libre pa ang registration fee, just top-up for as low as
            P100 and you’re ready to go. Gusto mo na bang simulan ang taon nang
            may sariling negosyo? Click{' '}
            <a
              className="inline-link"
              href={`https://${process.env.GATSBY_REACT_APP_HOSTNAME}/sign-up`}
            >
              here
            </a>{' '}
            to sign-up as Digipay Agent.
          </Text>

          <Text>Hanggang sa muli, ka-Digipay!</Text>
        </Section>
      </Column>
    </Row>
  </Layout>
)

export default ReasonsWhy
